@import "colors";

.cookie-banner {
  background-color: $cookie-banner-background-color;
  padding: 0 20px;

  .cookie-message {
    float: left;
    font-size: 0.8rem;
    color: $cookie-banner-font-color;
  }

  .button-close {
    float: right;
    background-color: $cookie-banner-background-color;
    border: none;
    box-shadow: none;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8rem;
    color: $cookie-banner-font-color;
  }
}
