@import "colors";
@import "fonts";

.review-page-container {
  max-width: 100%;
  width: 100%;
  padding: 30px 40px;
}

.review-tool-card.card {
  border: none;
  background-color: $light-yellow;
}

.review-tool-card-title {
  border-bottom: 1.5px solid $primary-color;
  color: $primary-color;
  font-family: $page-font;
  display: flex;
  font-weight: 600;
}

.review-tool-props-value {
  font-family: $page-font;
}

.review.tool-link {
  color: $links-background-color;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.visibility-button {
  font-size: 15px;
  font-family: $page-font;
  font-weight: 600;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.visibility-button:focus {
  outline: none;
}

.visibility-img {
  width: 22px;
  margin: 0 8px;
}

.review-buttons-container {
  display: flex;
  justify-content: flex-end;
}
