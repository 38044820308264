@import "colors";
@import "fonts";

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.search-banner-container.container {
  max-width: 100%;
  padding: 8% 30px 8% 30px;
  background-color: $white;
}

.search-banner-title {
  color: $primary-color;
  font-weight: 200;
  font-family: $page-font;
  margin-bottom: 30px;
}

.banner-title-brand {
  font-weight: 700;
}

.banner-secondary-text {
  color: $primary-color;
  font-family: $page-font;
  margin-bottom: 30px;
}

.search-banner-button {
  background-color: $primary-color !important;

  img {
    height: 20px;
  }
}

.search-banner-dropdown {
  .dropdown-toggle {
    background-color: $white !important;
    border: 2px solid $primary-color !important;
    color: $primary-color !important;
    box-shadow: 0 !important;
    width: 100%;
  }

  .dropdown-menu {
    background-color: $secondary-color !important;
    border: 2px solid $primary-color !important;
  }

  .dropdown-item {
    color: $primary-color;
    cursor: pointer;
  }
}

.search-banner-or {
  line-height: 2.5;
  text-align: center;
}

.feedback-banner-container.container {
  max-width: 100%;
  padding: 4% 30px 4% 10%;
  background-color: $primary-color;
  color: white;
  font-weight: 200;
  font-family: $page-font;
}

.feedback-banner-title {
  font-size: 1.75em;
  font-weight: 700;
}

.feedback-banner-content {
  margin: 30px 0;
  font-size: 0.9em;
}

.feedback-banner-content > a {
  font-weight: 400;
  color: white;
  text-decoration: underline;
}

.feedback-banner-content > a:hover {
  color: white;
  font-size: 1.05em;
}

.feedback-banner-content > a:visited {
  color: white;
}

.feedback-banner-image {
  height: 80px;
  margin-bottom: 30px;
}
