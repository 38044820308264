@import "colors";
@import "fonts";

.authentication-container.container {
  max-width: 100%;
  background-color: $primary-color;
  color: $primary-color;
  font-weight: 200;
  font-family: $page-font;
}

.authentication-content {
  background-color: white;
  padding: 40px;
}

.authentication-title {
  margin-bottom: 10%;
  font-weight: 200;
  text-align: center;
}

.authentication-title-action {
  font-weight: 700;
}

.authentication-form input {
  border-color: $primary-color;
  margin-bottom: 10px;
}

.authentication-footer {
  text-align: center;
  margin-top: 4%;
}

.authentication-forgot-password-link {
  text-align: right;
}

.authentication-button.btn {
  background-color: $buttons-background-color;
  font-weight: 700;
  margin-top: 5%;
  border-color: $buttons-background-color;
}
