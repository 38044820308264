.category-container {
  padding-top: 1.25rem;
}

.category-container.container {
  max-width: 100%;
  width: 100%;
  padding: 30px;
}

.category-container-title {
  font-family: $page-font;
  font-weight: 500;
  color: $primary-color;
  margin-bottom: 1em;
  padding-left: 30px;
}

.category-grid-container {
  max-width: 100%;
  width: 100%;
  padding: 30px;
}

.category-grid-title {
  font-family: $page-font;
  font-weight: 200;
  color: $primary-color;
  margin-bottom: 0.5em;
}

.category-grid-item-container {
  margin: 0px;

  label {
    font-family: $page-font;
    font-weight: 400;
    color: $primary-color;
    padding: 0px;
  }

  .disabled {
    color: $light-gray;
  }

  .category-grid-item-body {
    padding: 10px 20px 0px 10px;
  }
}

.category-grid-item-img {
  width: 14px;
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.category-grid {
  margin-right: 10%;
  max-width: 100%;
}


.category-list-container {
  padding-top: 1.25rem;
}

.category-list-container.container {
  margin-top: 1em;

  label {
    margin-bottom: 0px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    padding-bottom: 8px;
    font-family: $page-font;
    font-weight: 400;
    color: $primary-color;
  }

  h6 {
    font-family: $page-font;
    font-weight: 700;
    color: $primary-color;
    margin-bottom: 1em;
  }
}