@import "colors";
@import "fonts";

.post-grid.container {
  margin-right: 10%;
  max-width: 100%;
}

.post-card.card {
  border: none;
  background-color: $tool-background-color;
  margin-bottom: 10px;
}

.post-card-title {
  border-bottom: 1.5px solid $primary-color;
  padding-bottom: 0.2em;
  display: flex;

  .dv-title {
    a,
    div {
      color: $primary-color;
      font-family: $page-font;
      font-weight: 600;
      margin-right: 0.3em;
    }

    a:hover {
      color: $primary-color;
    }

    label {
      margin: 0px;
    }
  }
}

.post-card-body {
  margin-top: 1.2em;
}

.post-props-container {
  flex-direction: column;
  padding-right: 40px;
}

.post-props-title {
  font-family: $page-font;
  font-weight: 700;
  color: $primary-color;
}

.post-props-value {
  font-family: $page-font;
}

.post-card-description {
  font-family: $page-font;
  font-size: 0.9em;
  font-weight: 200;
}

.posts-page-container.container {
  max-width: 100%;
  width: 100%;
  padding: 30px;
}

.posts-title {
  font-family: $page-font;
  font-weight: 700;
  color: $primary-color;
  margin-bottom: 1em;
}

.post-bar-title {
  font-family: $page-font;
  font-weight: 500;
  color: $primary-color;
  margin-bottom: 1em;
}

.post-view-title {
  font-family: $page-font;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 30px;
}

.post-view-container.container {
  width: 100%;
  margin: 0;
}

.post-draft-button-container {
  float: right;
}
