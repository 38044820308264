@import "colors";
@import "fonts";

.header-container {
  background-color: $white;
}

.header-logo {
  height: 55px;
  margin-left: 5%;
}

.nav-link {
  white-space: nowrap;
  color: $purple;
}

.toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
