.comment-container {
  margin-bottom: 14px;
}

.comment-item-title {
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 1em;
}

.post-card-title > .dv-title > .comment-item-title-date {
  margin-left: 0.3em;
  font-size: 0.8em;
  margin-top: 1em;
}

.card-comment-body {
  margin-top: 0.8em;
  font-size: 0.95em;
}

.other-comments-title {
  font-family: $page-font;
  font-size: 1.4em;
  font-weight: bold;
  color: $primary-color;
  margin-top: 1.2em;
  margin-bottom: 0.8em;
}

.card-title > .comment-dv-title > .action-button-div {
  float: right;
  margin-right: 0px;
}

.comment-dv-title {
  width: 100%;
}

.btn-small {
  margin-left: 0.5em;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
}

.btn-comment-edit {
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-comment-delete {
  background-color: $dark-red;
}

.btn-comment-edit-cancel {
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.edit-comment-container {
  width: 100%;
}
