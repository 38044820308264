@import "colors";
@import "fonts";

.tool-view-container.container {
  width: 100%;
  margin: 0;

  .btn.tool-link {
    color: #fff;
    background-color: #6b7fd7;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
    text-align: left;
    font-size: 14px;
    float: left;
    margin-right: 5px;
    padding: 5px;
    width: 130px;
  }
}

.tool-view-properties {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 4em;
}

.tool-view-links-container {
  display: flex;
  flex-direction: column;
}

.tool-view-links-container > a {
  margin-bottom: 1em;
}

.tool-free {
  color: $free;
}

.average-rating-container {
  display: inline-block;
}

.average-rating-container .dv-star-rating {
  float: left;
  margin-bottom: 0.45em;
}

.average-rating-count-label {
  font-family: $page-font;
  font-weight: 600;
  font-size: 0.9em;
  width: fit-content;
  padding-left: 10px;
}

.user-rating-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.user-rating-container .dv-star-rating {
  display: inherit;
  float: left;
  margin-bottom: 1em;
  width: fit-content;
}

.user-rating-title {
  font-family: $page-font;
  font-weight: 700;
  color: $gray;
}

.tool-view-title {
  font-family: $page-font;
  font-weight: 600;
  font-size: 2em;
  margin-bottom: 30px;
}

.tool-view-short-description {
  font-family: $page-font;
  font-weight: bold;
  margin-bottom: 15px;
}

.action-buttons-row {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.tool-back-to-search {
  padding-left: 0px;
}

.tool-back-to-search-img {
  width: 18px;
  margin-bottom: -2px;
}
