@import "colors";

.badge {
  margin-right: 5px;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 1.5em;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #30234d;
  border-radius: 0.25rem;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  z-index: 100;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 100%;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin-left: -40px;
}
div.ReactTags__suggestions li mark {
  padding: 0;
  text-decoration: underline;
  background: none;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: $light-purple;
  cursor: pointer;
}
