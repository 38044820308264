@import "fonts";
@import "colors";

.add-tool-container.container {
  margin: 5% 0 0 0;
}

.add-post-container.container {
  margin-top: 30px;
}

.add-form-title {
  font-family: $page-font;
  color: $primary-color;
  margin-bottom: 5%;
  h2 {
    font-weight: 600;
  }
}

.add-form-text-fields-container > label {
  font-weight: 600;
  margin-bottom: 0.1em;
}

.add-form-text-fields-container > input,
.add-form-text-fields-container > select,
.add-form-text-fields-container > textarea {
  border-color: $primary-color;
  margin-bottom: 1.5em;
}

.add-form-text-fields-container > textarea {
  height: 120px;
}

.checkbox-wrapper {
  margin: 1% auto;
}

.checkbox-wrapper > label {
  font-family: $page-font;
  font-weight: 600;
  color: $primary-color;
}

.add-form-button.btn {
  font-size: 15px;
  font-family: $page-font;
  font-weight: 600;
  color: white;
  background-color: $primary-color;
  border-color: $primary-color;
  border-radius: 0.25rem;
}

.cancel-button.btn,
.delete-button.btn {
  font-size: 15px;
  font-family: $page-font;
  font-weight: 600;
  color: white;
  border-radius: 0.25rem;
}

.delete-button.btn {
  background-color: $dark-red;
}

.add-form-text-fields-container .editor-toolbar {
  border-top: 1px solid $primary-color;
  border-left: 1px solid $primary-color;
  border-right: 1px solid $primary-color;
}

.add-form-text-fields-container .CodeMirror {
  border: 1px solid $primary-color;
}
