@import "colors";
@import "fonts";

.tool-grid.container {
  margin-right: 10%;
  max-width: 100%;
}

.tool-card.card {
  border: none;
  background-color: $tool-background-color;
}

.tool-card-title {
  border-bottom: 1.5px solid $primary-color;
  padding-bottom: 0.2em;
  display: flex;

  .dv-title-free {
    width: calc(100% - 100px);
    float: left;

    a,
    div {
      color: $primary-color;
      font-family: $page-font;
      font-weight: 600;
      margin-right: 0.3em;
    }

    a:hover {
      color: $primary-color;
    }

    .tool-free-field {
      font-size: 0.6em;
      color: $free;
      display: inline-block;
    }

    label {
      margin: 0px;
    }
  }

  .dv-star-rating {
    float: right;
    font-size: 0.8em;
    align-self: flex-end;
  }

  .dv-star-rating-star {
    margin-bottom: 0;
  }
}

.non-editable {
  display: flex;

  .dv-star-rating-star {
    cursor: default !important;
  }
}

.tool-card-body {
  margin-top: 1.2em;
}

.tool-props-container {
  flex-direction: column;
  padding-right: 10px;
}

.tool-props-title {
  font-family: $page-font;
  font-weight: 700;
  color: $primary-color;
}

.tool-props-value {
  font-family: $page-font;
}

.tool-props-value.short-description {
  font-weight: bold;
}

.btn.tool-link {
  color: $links-color;
  background-color: $links-background-color;
  text-decoration: none;
  font-family: $page-font;
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
  float: left;
  margin-right: 5px;
  padding: 5px;
}

.btn.tool-link:hover {
  color: $links-color;
}

.tool-link-img {
  width: 14px;
  margin-right: 5px;
  margin-top: -5px;
}

.tool-card-description {
  font-family: $page-font;
  font-size: 0.9em;
  font-weight: 200;
}

.search-page-container.container {
  max-width: 100%;
  width: 100%;
  padding: 30px;
}

.catalog-title {
  font-family: $page-font;
  font-weight: 700;
  color: $primary-color;
  margin-bottom: 1em;
}

.search-bar-title {
  font-family: $page-font;
  font-weight: 500;
  color: $primary-color;
  margin-bottom: 1em;
}

.category-select-container select {
  background-color: white;
  color: $primary-color;
  border-color: $primary-color;
  margin-top: 20px;
}

.checkbutton-form-group.form-group {
  display: block;
}

.filter-input-checkbox.form-check-input {
  position: relative;
  margin-top: 0;
  margin-left: 0;
}

.filter-input-label {
  font-family: $page-font;
  font-weight: 600;
  color: $primary-color;
  margin-left: 0.8em;
  margin-bottom: 0;
}

.row.equal-height-cards {
  display: flex;
  flex-wrap: wrap;
}

.row.equal-height-cards > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.row.equal-height-cards .card {
  flex: 1;
  margin-bottom: 20px;
}
