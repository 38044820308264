@import "header";
@import "footer";
@import "fonts";
@import "authentication";
@import "homepage";
@import "toolview";
@import "addform";
@import "catalog";
@import "categories";
@import "cookiebanner";
@import "review";
@import "posts";
@import "tags";
@import "comments";

body {
  font-family: $page-font !important;
}

/* Start Sticky Footer Trick */
:root {
  —space: 1.5em 0;
  —space: 2em 0;
}

#root > div:first-child {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  padding: var(-space) var(—space) 0;
  width: 100%;
}

.main-content:after {
  content: "\00a0";
  display: block;
  margin-top: var(—space);
  height: 0;
  visibility: hidden;
}

.spinner-grow.spinner {
  width: 10rem;
  height: 10rem;
  margin-left: calc(50% - 10rem);
}

.spinner-grow.spinner-inline {
  width: 40px;
  height: 40px;
  margin-left: calc(50% - 40px);
}
/* End Sticky Footer Trick */

.required-input {
  position: relative;
}

.required-input::after {
  content: "*";
  position: absolute;
  right: 5px;
  top: +3px;
  color: red;
}

.required-message {
  color: red;
}

.search-bar-container input {
  background-color: white;
  color: $primary-color;
  border-color: $primary-color;

  @include placeholder {
    font-family: $page-font;
    font-weight: 300;
  }
}

.search-bar-container.container {
  margin-left: initial;
  margin-right: initial;
  margin-bottom: 1.5em;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}
