$dark-purple: #30234d;
$light-purple: #dad7e0;
$dark-gray: #150b2b;
$gray: #8c8c8c;
$light-gray: #d1d1d1;
$dark-yellow: #ffb400;
$light-yellow: #fffaf1;
$dark-blue: #6b7fd7;
$light-blue: #f6f8fc;
$dark-red: #f0544f;
$light-red: #fef5f5;
$dark-green: #197278;
$light-green: #f0f6f6;
$white: #ffffff;
$purple: #2e244a;

$primary-color: $dark-purple;
$secondary-color: $light-purple;
$footer-background-color: #000;
$links-color: #fff;
$links-background-color: $dark-blue;
$buttons-background-color: $dark-blue;
$free: $dark-green;
$tool-background-color: $light-blue;
$tool-description-color: #8c8c8c;
$cookie-banner-background-color: #000;
$cookie-banner-font-color: #fff;