@import "colors";
@import "fonts.scss";

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.footer * {
  font-family: $page-font;
}

.footer-sitemap {
  list-style: none;
  padding-left: 0;
}

.footer-sitemap > li > a {
  color: white;
}

.footer-sitemap > li > :visited {
  color: white;
}

.footer-sitemap > li > hover {
  text-decoration: underline;
}

.footer {
  background-color: $footer-background-color;
  color: white;
  font-size: 0.9em;
  font-weight: lighter;
  padding-top: 5%;
  padding-bottom: 5%;
  flex: 1 0 auto;
}

.footer-container.container {
  padding-left: 30px;
}

.footer-col-title {
  font-weight: 600;
  width: fit-content;
  padding-bottom: 1em;
  display: inline-block;
}

.footer-col-content {
  font-weight: 200;
}

.footer-logo {
  margin-bottom: 20px;
  height: 45px;
}

#about-us-col {
  width: 25%;
}

.newsletter-container {
  margin-bottom: 20px;
}

.newsletter-container input {
  border-radius: 0.25rem;
  border: 1px solid black;
  font-family: $page-font;

  @include placeholder {
    padding-left: 1em;
    font-weight: 200;
  }
}

.newsletter-container button {
  color: white;
  background-color: #1b1e21;
  margin-left: 1em;
  border-radius: 0.25rem;
}

.newsletter-title {
  font-size: 2em;
  margin-top: -20px;
}
